<template> </template>

<script>
export default {
    created() {
        this.$store.commit('logout');
        this.$store.commit('local_setting', { auto_login: false });
        this.$message.success('已退出登录');
        setTimeout(() => {
            this.$router.replace('/index');
        }, 500);
    },
};
</script>

<style></style>
